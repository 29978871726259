* {
  box-sizing: border-box;
}
.color-primary {
  color: #ff3652;
}
.color-secondary {
  color: #000;
}
/* Message */
.message {
  width: 60%;
  text-align: center;
  margin: 25px auto 15px auto;
}

.message h1 {
  font-size: 25px;
  line-height: 1.3;
}

.message p {
  font-size: 18px;
}
/* Containers */

.container-results {
  width: 100%;
  background-color: #ddd;
}

.container-options {
  display: flex;
  align-items: center;
}

.container-options .container-option:not(:first-child) {
  margin-left: 48px;
}

.container-options select {
  min-width: 100px;
  height: 48px;
  padding: 0px 8px;
}
.ais-SortBy-option--selected {
  background-color: #ff3652;
}
.container-footer {
  margin: 4rem 0;
}

/* Panel */

.container-body .ais-Panel {
  border-top: 1px solid #ebecf3;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.ais-Panel-header {
  font-family: Hind, sans-serif;
}

.link-active {
  color: #ff3652;
}
/* Search box */

.header {
  .ais-SearchBox {
    height: 48px;
    @apply w-full;
  }
  .ais-SearchBox .ais-SearchBox-input {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 48px 0 rgba(0, 0, 0, 0.2);
    height: 48px;
  }
  .ais-SearchBox-reset,
  .ais-SearchBox-loadingIndicator {
    width: 64px;
    height: 56px;
  }
  .ais-SearchBox-submit {
    width: 46px;
    height: 46px;
    @apply pl-1 pr-3 mt-4 bg-primary rounded-l-md top-[49px];
  }
  .ais-SearchBox-submitIcon {
    fill: #eee;
  }
}

.header-mobile {
  .ais-SearchBox-input {
    @apply pl-10 top-11;
  }
  .ais-SearchBox-reset,
  .ais-SearchBox-loadingIndicator {
    width: 30px;
    height: 30px;
  }
  .ais-SearchBox-input[value]:not([value=""]) + .ais-SearchBox-submit {
    top: 52px;
    @apply mt-0;
  }
  .ais-SearchBox-submit {
    @apply pl-1 pr-3 max-h-12 mt-3;
  }
}

.header, .header-mobile, .subheader {
  .ais-SearchBox-reset,
  .ais-SearchBox-loadingIndicator {
    @apply -top-6;
  }
}

.header, .header-mobile {
  .ais-SearchBox-submit .ais-SearchBox-submitIcon {
    width: 16px;
    height: 16px;
    margin-left: 7px;
  }
}

.header, .header-mobile {
  .ais-SearchBox .ais-SearchBox-input::placeholder {
    color: rgba(0, 0, 0, 0.5);
    opacity: 1; /* Firefox */
  }
}

.header-mobile, .subheader {
  .ais-SearchBox {
    @apply max-h-12;
  }
}

.search-marcas .ais-SearchBox-reset {
  @apply top-[-18px];
}

.ais-SearchBox-form {
  width: 100%;
  margin-bottom: 0;
}

.ais-SearchBox-input:-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-SearchBox-input::-ms-input-placeholder {
  color: rgba(33, 36, 61, 0.24);
}

.ais-RefinementList .ais-SearchBox-input {
  font-family: Hind, sans-serif;
  /*
    The "Hind" font family is vertically off-balance.
    Adding some padding top makes it more vertically aligned.
  */
  padding-top: 2px;
}

/* Hits */
.hit-category-icon {
  background-image: url('/images/iconos/spritesheet.png');
  background-repeat: no-repeat;
}

.badge {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ff3652;
}
.badge-active {
  position: relative;
}

.badge-active span {
  position: absolute;
  top: -5px;
  right: -7px;
  z-index: 1;
  animation-name: badge;
  animation-duration: 0.1s;
}

@keyframes badge {
  0% {
    width: 0px;
    height: 0px;
  }
  25% {
    width: 2.5px;
    height: 2.5px;
  }
  50% {
    width: 5px;
    height: 5px;
  }
  75% {
    width: 7.5px;
    height: 7.5px;
  }
  100% {
    width: 10px;
    height: 10px;
  }
}

.badge-hidden span {
  display: none;
}

.hit-sale-label {
  @apply
    absolute pt-4 bg-primaryLighten text-white text-center font-semibold rounded-full z-1
    text-lg;
  width: 60px;
  height: 60px;
  top: 5px;
  right: 5px;
}

.sm\:hit-sale-label {
  @apply
    absolute pt-3 bg-primaryLighten text-white text-center font-semibold rounded-full z-1
    text-xs;
  width: 40px;
  height: 40px;
  top: -20px;
  right: -10px;
}

.hit-price-before {
  text-decoration: line-through;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.54);
}

.hit {
  font-size: 14px;
  line-height: 18px;
  background-color: #fff;
}

.hit h1 {
  font-size: 14px;
}

.hit-description-container {
  display: grid;
  grid-template-columns: 40px auto;
}

.hit-description {
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.54);
  height: 40;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.hit-info-container {
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 5px 10px;
}

.hit-em {
  color: #e2a400;
  font-size: 11px;
  font-weight: 600;
}

.hits-empty-state {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 300px;
}

.hits-empty-state-title {
  font-family: Hind;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0;
  text-align: center;
}

.hits-empty-state-description {
  color: rgba(35, 37, 51, 0.6);
  font-size: 0.875rem;
  text-align: center;
}

.hits-empty-state .ais-ClearRefinements {
  margin-top: 1rem;
}

.hits-empty-state .ais-ClearRefinements-button--disabled {
  display: none;
}

/* ToggleRefinement */

.ais-ToggleRefinement-label {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.ais-ToggleRefinement-checkbox {
  font: inherit;
  margin-left: 1rem;
  margin-right: 0;
  position: relative;
}

.ais-ToggleRefinement-checkbox:checked::before {
  color: #ff3652;
}

.ais-ToggleRefinement-checkbox::before {
  align-items: center;
  color: rgba(33, 36, 61, 0.32);
  content: 'No';
  display: flex;
  font-size: 0.8rem;
  height: 16px;
  position: absolute;
  right: 38px;
}

.ais-ToggleRefinement-checkbox:checked::before {
  content: 'Yes';
}

.ais-ToggleRefinement-count {
  display: none;
}

/* RatingMenu */

.ais-RatingMenu-item:not(.ais-RatingMenu-item--selected) {
  opacity: 0.5;
}

.ais-RatingMenu-starIcon {
  margin-right: 0.5rem;
}

/* Hide all mobile-specific design on desktop */

@media (min-width: 900px) {
  [data-layout='mobile'] {
    display: none;
  }
}
